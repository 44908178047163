<template>
	<div class="p-grid menu-top" style="color: black">
		
		<div class="p-col-4 p-md-4 p-lg-6 menu-sup" v-for="(stats, index) in  menu" :key="index" style="padding: 0em 3em 0.5em 3em;">
			<h4>{{stats.label}}</h4>
		
			<div class="p-grid ">				
				<div class="p-col-4 menu-top-icons" v-for="(bot, index) in  stats.items" :key="index">
					<button class="p-link movil" style="text-align: center;" @click="top_page(bot.to)">
						<span :class="'menu-top-icon '+bot.icon"></span><br>
						<span class="ocultar">{{bot.label}}</span>
					</button>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import router from '../routes';

	export default {
		props: {
			menu: {
				type: Array,
				default: null
			},
		},

		data() {
			return {
				i18n: null
			}
		},
		created() {
			
		},
		methods: {
			top_page(pag){
				router.push({ path: pag });
			},
		},
	}
</script>

<style>



</style>
